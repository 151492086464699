
import { Component, Vue, } from 'vue-property-decorator'

import CoreContainer from '@/layouts/CoreContainer/Index.vue'

@Component({
  components: {
    CoreContainer,
  },
})
export default class AppLayout extends Vue {}
